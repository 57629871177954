<template>
    <div class="container text-center col-lg-6">
 
        <div class="">
            <h1 class="fw-light">Esame di <span class="fw-bold">{{ name }}</span> <i class="bi bi-info-circle-fill" @click="showAnagrafica()"></i></h1>
            <!-- <p>Istruzioni <a href="#"><i class="bi bi-info-circle-fill"></i></a></p> -->
        </div>

        <!-- TABELLE DEI PIATTI -->
        <div v-if="esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_piatti__c">
            <div class="">
                <div class="col">
                    <h4 class="fw-bold mb-2 text-start">Piatti</h4>
                </div>
            </div>
            <div class="d-flex" v-for="piatto of piatti" v-bind:key="piatto.Id">
                <div class="col">
                    <table class="table table-bordered border-secondary">
                        <tbody>
                            <tr><th class="bgAlma text-white text-start" scope="col" :colspan="colspan"> {{ piatto.Tipo_Piatto__c }}: {{ piatto.Name }}</th></tr>
                            <tr>
                                <td v-for="(item, key) in piatto.voti" v-bind:key="key">
                                    <div class="d-flex">
                                        <div :class="piatto.voti[key] == ''? 'col pe-0': 'col'">
                                            <select class="form-select rounded-pill py-0" v-model="piatto.voti[key]" v-on:change="saveVotiPiatto(key, piatto.voti[key], piatto.Id, '', false)">
                                                <option value="">{{ key }}</option>
                                                <option v-for="v in votiInTrentesimi" v-bind:key="v">{{ v }}</option>
                                            </select>
                                        </div>
                                        <div v-if="piatto.voti[key] === ''" class="col-2 px-0"><i class="bi bi-exclamation-triangle-fill text-danger"></i></div>
                                    </div>
                                    <input v-if="piatto.voti[key] != '' && piatto.voti[key] < 18" placeholder="Note"
                                        :class="piatto.commenti[key] == '' ? 'mt-2 border-danger bg-danger bg-opacity-25':'mt-2 border'"
                                        v-model="piatto.commenti[key]" type="text"
                                        v-on:change="saveVotiPiatto(key, piatto.voti[key], piatto.Id, piatto.commenti[key])">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_piatti__c > 1" class="ms-3 align-content-end">
                    <table class="table table-bordered border-secondary">
                        <tbody>
                            <tr><th class="bgAlma text-white" scope="col">Media</th></tr>
                            <tr><td :class="piatto.media < 18 ? 'fw-bold bg-danger bg-opacity-25':''">{{ piatto.media }}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
 
        <!-- TABELLA CON INDICATORI -->
        <!-- <div>
            <div :class="pochiVotiEsame? 'd-flex':''">
                <div :class="pochiVotiEsame? 'flex-fill':''" v-for="(item, key) in esame.indicatoriEsame" v-bind:key="key">
                    <table class="table table-bordered border-secondary">
                        <tbody>
                            <tr><th class="bgAlma text-white" scope="col" :colspan="colspan">{{ key }}</th></tr>
                            <tr>
                                <td>
                                    <div>
                                        <div :class="esame.indicatoriEsame[key] == ''? 'col pe-0': 'col'">
                                            <select class="form-select rounded-pill py-0" v-model="esame.indicatoriEsame[key]" v-on:change="saveVotiIndicatoriEsame(key, esame.indicatoriEsame[key])">
                                                <option value="" hidden>{{ key }}</option>
                                                <option v-for="v in votiInTrentesimi" v-bind:key="v">{{ v }}</option>
                                            </select>
                                        </div>
                                        <div v-if="esame.indicatoriEsame[key] === ''" class="col-2 px-0"><i class="bi bi-exclamation-triangle-fill text-danger"></i></div>
                                    </div>
                                    <input v-if="esame.indicatoriEsame[key] != '' && esame.indicatoriEsame[key] < 18" placeholder="Note"
                                        :class="esame.commentiIndicatoriEsame[key] == undefined ? 'mt-2 border-danger bg-danger bg-opacity-25':'mt-2 border'"
                                        v-model="esame.commentiIndicatoriEsame[key]" type="text"
                                        v-on:change="saveVotiIndicatoriEsame(key, esame.indicatoriEsame[key], esame.commentiIndicatoriEsame[key])">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="pochiVotiEsame" class="col-2 align-content-end ps-0">
                    <table class="table table-bordered border-secondary">
                        <tbody>
                            <tr><th class="bgAlma text-white" scope="col">Media</th></tr>
                            <tr><td :class="mediaIndicatoriEsame < 18 ? 'fw-bold bg-danger bg-opacity-25':''">{{ mediaIndicatoriEsame }}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->

        <!-- PIECE -->
        <div>
            <div :class="pochiVotiEsame? 'd-flex':''">
                <div :class="pochiVotiEsame? 'flex-fill':''" v-for="(item, key) in esame.indicatoriEsame" v-bind:key="key">
                    <table class="table table-bordered border-secondary">
                        <tbody>
                            <tr><th class="bgAlma text-white" scope="col" :colspan="colspan">{{ key }}</th></tr>
                            <tr>
                                <td>
                                    <div>
                                        <div :class="esame.indicatoriEsame[key] == ''? 'col pe-0': 'col'">
                                            <select class="form-select rounded-pill py-0" v-model="esame.indicatoriEsame[key]" v-on:change="saveVotiIndicatoriEsame(key, esame.indicatoriEsame[key])">
                                                <option value="" hidden>{{ key }}</option>
                                                <option v-for="p in punteggio1_5" v-bind:key="p">{{ p }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
 
    <!-- <div>
            <div class="row pt-3">
                <div class="col">
                    <p class="text-start mb-2">NOTE / COMMENTI</p>
                    <input class="border px-3" v-model="note"/>
                </div>
                <div class="col-2 align-content-end ps-0">
                    <table class="table table-bordered border-secondary">
                        <tbody>
                            <tr><th class="bgAlma text-white" scope="col">Media<br>Tot</th></tr>
                            <tr><td :class="mediaTotale < 18 ? 'fw-bold bg-danger bg-opacity-25':''">{{ mediaTotale }}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
 
 
        <div class="d-flex mb-3">
            <div class="col-lg-6 col-md-6"></div>
            <div class="col col-lg-4 col-md-4 pe-0">
                <h4 class="fw-bold py-2 rounded-start bgAlma text-white">Media totale</h4>
            </div>
            <div class="col-2 ps-0 pe-0">
                <h4 class="fw-bold py-2 rounded-end align-content-center bgAlma text-white">{{ mediaTotale }}</h4>
            </div>
        </div>
 
    </div>
 
 </template>
 
 
 <script>
 import { create, all } from "mathjs";
 const math = create(all);
 
 export default {
    name: 'vediEsame',
    components: {},
    props: {
        name: String,
        piatti: Array,
        esame: Object,
        colspan: Number,
    },
 
    data() {
        return {
            votiInTrentesimi: [],
            punteggio1_5: [],
            mediaIndicatoriEsame: 0,
            mediaTotale: 0,
            mTipoVoto: new Map(),
            pochiVotiEsame: false,
        }
    },
 
    mounted () {
        console.log('mounted di vediEsame.vue')
        console.log('this.esame',this.esame)
        for(var i = 30; i >= 0; i--) {
            this.votiInTrentesimi.push(i)
        }
        for(var j = 5; j >= 0; j--) {
            this.punteggio1_5.push(j)
        }
        console.log('this.esame.Voti_Esami__r')
        console.log(this.esame.Voti_Esami__r)
        if(this.esame.Voti_Esami__r && this.esame.Voti_Esami__r.records.length > 0) {
            console.log(this.esame.Voti_Esami__r.records)
            for(var votoEsame of this.esame.Voti_Esami__r.records) {
                if(votoEsame.Piatto__c) this.mTipoVoto.set(votoEsame.Tipo__c + votoEsame.Piatto__c, votoEsame.Id)
                else this.mTipoVoto.set(votoEsame.Tipo__c, votoEsame.Id)
                if(votoEsame.Piatto__c) this.saveVotiPiatto(votoEsame.Tipo__c, votoEsame.Voto__c, votoEsame.Piatto__c, votoEsame.Commento__c, true)
                else this.saveVotiIndicatoriEsame(votoEsame.Tipo__c, votoEsame.Voto__c, votoEsame.Commento__c, true)
            }
        } 
        if(this.esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_esami__c <= 2) this.pochiVotiEsame = true
    },
 
    methods: {
        showAnagrafica(){
            console.log('showAnagrafica')
            var p = {
                Opportunita__c: this.esame.Opportunita__c,
                Account__c: this.esame.Opportunita__r.AccountId,
                Account__r: { Name: this.name }
            }
            console.log(p)
            this.$emit('setStudenteAnagrafica', p)
        },
        saveVotiPiatto(indicatore, voto, idPiatto, commento, lettura) {
            console.log('indicatore', indicatore)
            console.log('voto', voto, typeof(voto))
            console.log('idPiatto', idPiatto)
 
 
            for(var piatto of this.piatti) {
                if(piatto.Id == idPiatto) {
                    console.log('piatto', piatto)
                    piatto.voti[indicatore] = Number(voto)
                    piatto.commenti[indicatore] = commento
 
 
                    piatto.listaVoti = []
                    for(var v of Object.keys(piatto.voti)) {
                        piatto.listaVoti.push(piatto.voti[v])
                    }
                    piatto.media = math.round(math.mean(piatto.listaVoti),2)
                }
                console.log(piatto.voti)
            }
 
 
            if(!lettura) {
                var votoEsame = {
                    Name: this.esame.Name +' - '+ this.$store.state.userprofile.Name,
                    Docente__c : this.$store.state.userprofile.Id,
                    Esame_Individuale__c : this.esame.Id,
                    Piatto__c : idPiatto,
                    Tipo__c : indicatore,
                    Voto__c : Number(voto)
                }
                if(this.mTipoVoto.has(votoEsame.Tipo__c + votoEsame.Piatto__c)) votoEsame['Id'] = this.mTipoVoto.get(votoEsame.Tipo__c + votoEsame.Piatto__c)
 
 
                if(votoEsame.Voto__c < 18) votoEsame['Commento__c'] = commento
                else votoEsame['Commento__c'] = ''
                if(this.$store.state.nomeGiurato != '') {
                    votoEsame.Name = this.esame.Name +' - '+ this.$store.state.nomeGiurato
                    votoEsame['Giurato__c'] = this.$store.state.nomeGiurato
                }
 
 
                this.$store.dispatch('saveVotoEsame', {votoEsame: votoEsame})
                .then(resp => {
                    console.log('saveVotoEsame resp',resp)
                    this.mTipoVoto.set(resp.Tipo__c + resp.Piatto__c, resp.Id)
                })
            }
 
 
            this.calcolaMediaTotale()
        },
        saveVotiIndicatoriEsame(indicatore, voto, commento, lettura) {
            this.esame.indicatoriEsame[indicatore] = Number(voto)
            this.esame.commentiIndicatoriEsame[indicatore] = commento
 
 
            var listaVotiIndicatoriEsame = []
            for(var ind of Object.keys(this.esame.indicatoriEsame)) {
                listaVotiIndicatoriEsame.push(this.esame.indicatoriEsame[ind])
            }
            this.esame.mediaIndicatoriEsame = math.round(math.mean(listaVotiIndicatoriEsame),2)
            this.mediaIndicatoriEsame = this.esame.mediaIndicatoriEsame
 
 
 
 
            if(!lettura) {
                var votoEsame = {
                    Name: this.esame.Name +' - '+ this.$store.state.userprofile.Name,
                    Docente__c : this.$store.state.userprofile.Id,
                    Esame_Individuale__c : this.esame.Id,
                    Tipo__c : indicatore,
                    Voto__c : Number(voto)
                }
 
 
                if(this.mTipoVoto.has(votoEsame.Tipo__c)) votoEsame['Id'] = this.mTipoVoto.get(votoEsame.Tipo__c)
 
 
                if(votoEsame.Voto__c < 18) votoEsame['Commento__c'] = commento
                else votoEsame['Commento__c'] = ''
                if(this.$store.state.nomeGiurato != '') {
                    votoEsame.Name = this.esame.Name +' - '+ this.$store.state.nomeGiurato
                    votoEsame['Giurato__c'] = this.$store.state.nomeGiurato
                }
 
 
                this.$store.dispatch('saveVotoEsame', {votoEsame: votoEsame})
                .then(resp => {
                    console.log('saveVotoEsame resp',resp)
                    this.mTipoVoto.set(resp.Tipo__c, resp.Id)
                })
            }
 
 
            this.calcolaMediaTotale()
        },
        calcolaMediaTotale() {
            var listaMedieEsame = []
            // if(this.esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_esami__c) {
            //     listaMedieEsame.push(this.esame.mediaIndicatoriEsame)
            // }
            for(var piatto of this.piatti) {
                listaMedieEsame.push(piatto.media)
            }
            this.esame.mediaTotale = math.round(math.mean(listaMedieEsame),2) + this.esame.mediaIndicatoriEsame
            this.mediaTotale = this.esame.mediaTotale
        }
    }
 }
 </script>